import { postToSaleServer,postToItemServer,postToPropServer } from './common';
/**
 * 销售手册商品列表
 */
export async function moShopList(obj){
    return await postToSaleServer('/sale_book/item/list',obj);
}
/**
 * 销售手册分类列表
 */
export async function getShopCats(obj){
    return await postToSaleServer('/sale_book/cat/list',obj);
}
/**
 * 销售手册商品详情
 */
export async function shopDetailId(obj){
    return await postToItemServer('/sale-handbook/get-product',obj);
}
// 获取小程序二维码
export async function getWxCode(obj){
    return await postToItemServer(`/salebook/get-qr-code`,obj);
}
/**
 * 销售手册获取本公司分享产品统计列表
 */
export async function xsscShareList(){
    return await postToItemServer('/salebook/list-comp-shared-statistics');
}
// 询价单-列表-卖方
export async function enquiryListSeller(obj){
    return await postToSaleServer(`/sale_book/enquiry/list/seller`, obj);
}
// 询价单-详情
export async function getEnquiry(id){
    return await postToSaleServer(`/sale_book/enquiry/get/${id}`,);
}
// 询价单-工厂报价
export async function enquiryOffer(obj){
    return await postToSaleServer(`/sale_book/enquiry/offer`,obj);
}

/**
 * -----------------------提交金融备料调查问卷----------------------------
 */
export async function surveySubmit(obj){
    return await postToPropServer('/survey/beiliao/front/submit',obj);
}
/**
 * 查询金融备料调查问卷
 */
export async function surveyGet(obj){
    return await postToPropServer('/survey/beiliao/front/get',obj);
}